<template>
    <div class="about">
        <div class="about-banner"></div>
            
        <dl class="wrap1200">
            <dt>公司简介</dt>
            <dd>
                <p class="indent">中泰智本致力于做“最懂教学的产品”，关注用户诉求，超越用户期待。敬畏教育，不忘本质，营造健康的教育生态环境。将知识共享到每一片土壤，让教育更平等、便捷、高效。</p>
                <p class="indent">自成立以来，中泰智本不断汇聚优质的教育资源，基于信息化技术、AI技术，针对个性化学习以及更广泛领域进行研究创新，关注减轻教师负担增强教学效果，重视课堂学习与家庭学习学习场景的协同效果，促进教育与出版行业的创新、合作与共赢，更积极的投身教育公平与发展事业。</p>
                
            </dd>
        </dl>
        
        <dl class="wrap1200">
            <dt>公司文化</dt>
            <dd>
                <ul class="culture">
                    <li>
                        <img src="../../../assets/images/about_pic1.jpg" />
                        <div>
                            <h6>愿景</h6>
                            <p>做最懂教学的产品</p>
                        </div>
                    </li>					
                    <li>
                        <img src="../../../assets/images/about_pic2.jpg" />
                        <div>
                            <h6>使命</h6>
                            <p>将知识共享到每一片土壤</p>
                            <p>让教育更平等  便捷  高效</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/images/about_pic3.jpg" />
                        <div>
                            <h6>管理理念</h6>
                            <p>关爱员工   以终为始 </p>
                            <p>有效决策   高效执行</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/images/about_pic4.jpg" />
                        <div>
                            <h6>经营理念</h6>
                            <p>我们与用户同呼吸</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/images/about_pic5.jpg" />
                        <div>
                            <h6>价值观</h6>
                            <p>专注  敬业  创新  极致</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/images/about_pic6.jpg" />
                        <div>
                            <h6>产品理念</h6>
                            <p>植根教育  简捷实用</p>
                        </div>
                    </li>					
                </ul>
            </dd>
        </dl>
        
        <dl class="wrap1200">
            <dt>公司历程</dt>
            <dd class="progress">
                <div class="line"></div>
                <div class="period">
                    <span>2007年</span>
                    <p>中泰文化公司成立，立足中小学教育。</p>
                </div>
                <div class="period">
                    <span>2008 ~ 2012年</span>
                    <p>教辅纸质产品销售逐年递增，销量过亿。</p>
                    <p>组建“书僮网”，创建教辅网络产品《教辅宝》。</p>
                </div>
                <div class="period">
                    <span>2013年</span>
                    <p>与湖南大学数学系共建数据教辅工作室，探索开发教辅网络产品。</p>
                    <p>与湖南教育出版社合作，开发经营湘版独家教辅《教材解读》。</p>
                </div>
                <div class="period">
                    <span>2014年</span>
                    <p>与湖南大学附中合作开发高中同步全科产品《自主学习手册》，在全省所在名牌中学大受欢迎，自主学习理念与世界当代教育理念接轨。</p>
                    <p>纸质教辅产品销量跨越2亿新台阶。</p>
                    <p>与湖南大学数学系共同开发设计教辅移动端应用产品。</p>
                </div>
                <div class="period">
                    <span>2015年</span>
                    <p>与武汉、广州等地合作经营公司教辅网络产品《教辅宝》。</p>
                    <p>在本省永州等市州部分学校开始试用《教辅宝》，产生一定影响。</p>
                </div>
                <div class="period">
                    <span>2016年</span>
                    <p>以中泰文化技术团队为骨干，注册中泰智本科技公司。</p>
                    <p>受湖南少年儿童出版社委托，应用《教辅宝》基本构架开发制作E英语宝APP。</p>
                </div>
                <div class="period">
                    <span>2017年</span>
                    <p>E英语宝正式上市。</p>
                    <p>受湖南教育音像电子出版社委托，经营“堂堂网”。</p>
                    <p>与省新华书店系统合作，探索新形势下市场教辅经营新模式。</p>
                    <p>在北京注册北京中泰智本教育科技有限公司。</p>
                </div>
                <div class="period">
                    <span>2018年</span>
                    <p>E英语宝学生用户突破两百万，教师用户突破十万！</p>
                </div>
                <div class="period">
                    <span>2019年</span>
                    <p>整合知名基础教育信息化服务提供商北京慧之源信息技术有限公司。</p>
                </div>
            </dd>
        </dl>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/aboutUs';
</style>

<script>
    export default {
        data () {
            return {

            }
        }

    }
</script>